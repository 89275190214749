@import './../VariablesSASS.scss';

@keyframes opacityAnim {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.skillsMainContainer{
    display: flex;    
    width: 70%;
    height: auto;   
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    flex-direction: column;
    justify-content: center;
    animation: opacityAnim 2s ease;
    z-index: 3;
    background: transparent;
        .cvTextContainer{
            margin-bottom: 15px;
            margin-top: -15px;
            background: transparent;
            .cvText{
                display: flex;
                font-size: 22px;
                width: auto;
                height: auto;
                justify-content: center;
                background: transparent;
            }
        }
    .CvContainer{
        display: flex;  
        width: auto;
        height: auto;  
        justify-content: center; margin-right: 0; 
        margin-bottom: 15px;  
        background: transparent;
        z-index: 3;
        .cvFr{
            display: flex;
            width: auto;
            justify-content: center;
            align-items: center;   
            background: transparent;         
            a{
                display: flex;
                width: auto;
                height: auto;
                background: transparent;
            }
            img{
                display: flex;
                width: 100px;
                height: 100px;
                border-radius: 100%;
                box-shadow: 0px 0px 15px rgba(aqua, 0.50);
                background: transparent;
            }
            :hover{
                display: flex;
                border-radius: 100%;
                box-shadow: 0px 0px 25px $base-orange;
            }
        }
        .cvEng{
            display: flex;
            width: auto;
            margin-right: 15px;
            margin-left: 15px;
            justify-content: center;
            align-items: center;
            background: transparent;
            a{
                display: flex;
                width: auto;
                height: auto;
                background: transparent;
            }
            img{
                display: flex;
                width: 100px;
                height: 100px;
                border-radius: 100%;
                box-shadow: 0px 0px 15px rgba(aqua, 0.50);
                background: transparent;
            }
            :hover{
                display: flex;
                border-radius: 100%;
                box-shadow: 0px 0px 25px $base-orange;
            }
        }
    }    
    .titleSkills{
        display: flex;
        width: auto;
        height: auto;       
        justify-content: center;
        font-size: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
        color: aqua;
        background: transparent;
        z-index: 3;
    }
    .skillImagesContainer{
        display: flex;
        height: auto;
        width: auto;
        margin-top: auto;
        background: transparent;
        .hardSkillsContainer{
            display: flex;
            flex-wrap: wrap;
            height: auto;
            justify-content: center;
            width: auto;    
            background: transparent;        
            img{
                display: flex;
                width: 125px;
                height: 125px;
                margin: 15px;
                border-radius: 100%;
                box-shadow: 0px 0px 15px rgba(aqua, 0.50);
            }
            :hover{
                display: flex;
                box-shadow: 0px 0px 25px $base-orange;
            }
        }  
    }
    .softSkillsContainer{
        display: flex;
        width: auto;
        height: auto;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 25px;
        background: transparent;
        .softSkillText{
            display: flex;
            background: transparent;
            width: auto;
            height: auto; 
            margin-right: 10px;
            margin-left: 10px;    
            font-size: 28px; 
            color: white;   
            text-shadow: 0px 0px 15px rgba($base-orange, 0.30);                    
        }
    }
}

//Tablet 1250
@media (max-width: 1250px) {
    .skillsMainContainer{
        width: 90%;
        .skillImagesContainer{
            .hardSkillsContainer{
                img{
                    width: 110px;
                    height: 110px;
                }
            }               
        }
        .softSkillsContainer{
            .softSkillText{
                font-size: 25px;
            }    
        }
    }
}
//Mobile 485  -320 breakpoint idc
@media (max-width: 485px) {
    .skillsMainContainer{
        width: 90%;
        margin-top: 25px;
        .titleSkills{
            font-size: 20px;
        }
        .cvTextContainer{
            .cvText{
                font-size: 15px;
            }
        }
        .skillImagesContainer{
            .hardSkillsContainer{
                img{
                    width: 75px;
                    height: 75px;
                }
            }               
        }
        .softSkillsContainer{
            .softSkillText{
                font-size: 15px;
                margin-top: -15px;
            }    
        }
    }
}
