.LgMainContainer {
    display: flex;
    background-color: rgba(98, 102, 102, 0.527);
    width: 60px;
    height: 30px;
    border-radius: 15px;
    background: transparent;
    
    .ChoicesLg{
        display: flex;
        justify-content: space-between;

        .FrenchChoice, .EnglishChoice{
            display: flex;
            cursor: pointer;
            width: 30px;
            height: 30px;
            opacity: 50%;
            background-color: transparent;

            &.active{
                opacity: 100%;
            }

            &:hover{
                opacity: 100%;
            }
        }
     
    }
}