@import './../VariablesSASS.scss';

@keyframes opacityAnim {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.galleryContainer{
    display: flex;    
    width: 100%;
    height: auto;   
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    animation: opacityAnim 3s ease;  
    background-color: transparent;
    z-index: 3;
  
    .OCContainer{
        display: flex;
        width: auto;
        height: 100%;
        flex-direction: column; 
        justify-content: center;
        background-color: transparent;  
        .OpenClassRoomGalleryTitle{
            display: flex;       
            flex-direction: column ;
            justify-content: center;
            text-align: center;
            width: auto;
            height: auto;   
            font-size: 25px;
            margin-top: 25px;
            margin-bottom: 25px;
            background-color: transparent;
        }
        .CardGalleryOC{
            display: flex;
            width: auto;
            height: auto;
            margin-top: 25px;
            background-color: transparent;
            .CardMainContainer{
                display: flex;
                width: auto;
                height: auto;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 10%;
                margin-top: 5%;
                background-color: transparent;
                .expandedGalleryContainer{
                    display: flex;
                    width: auto;
                    height: auto;
                    margin-bottom: 2%;
                    margin-top: auto;
                    animation: opacityAnim 1.2s ease; 
                    background-color: transparent;
                    .arrayGallery.activeexpanded {
                        display: flex;
                        width: 400px;
                        height: 400px;
                        flex-direction: column;
                        background: white;
                        border-radius: 15px;
                        justify-content: center;
                        cursor: pointer;
                        overflow: hidden;
                        box-shadow: 0px 0px 25px rgba(aqua, 0.80);
                        margin-bottom: 15px;
                        margin-top: 5px;
                
                        .arrayGalleryBlock{
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            .arrayGalleryTitle{
                                display: flex;
                                width: auto;                                
                                height: 50px;
                                font-size: 32px;
                                box-shadow: none;
                                background: transparent;
                                justify-content: center;
                                align-items: center;
                                margin-top: 5px;
                            }
                            .arrayGalleryDetail{
                                display: flex;
                                width: auto;
                                height: 100%;
                                box-shadow: none;
                                justify-content: space-between;
                                align-items: center;
                                background: transparent;
                                .cardDetailData{
                                    display: flex;
                                    width: auto;
                                    height: 100%;
                                    flex-direction: column;
                                    .textDetailData{
                                        display: flex;
                                        width: auto;
                                        height: auto;
                                        color: rgb(168, 168, 168);
                                        box-shadow: none;
                                        background: transparent;
                                        text-align: center;
                                        justify-content: center;
                                        margin-top: auto;
                                        margin-bottom: auto;
                                        margin-right: 10px;
                                        margin-left: 10px;
                                    }
                                    .linkDetailBlock{
                                        display: flex;
                                        height: auto;
                                        flex-direction: column;
                                        margin-bottom: 15px;
                                        .linkDetailData{
                                            display: flex;
                                            justify-content: center;   
                                            margin-top: 10px;                        
                                        }
                                        .linkDetailData:hover{
                                            color: aqua;
                                        }
                                    }
                                }
                            } 
                        }  
                        .arrayGalleryImg{
                            display: flex;
                            width: auto;
                            height: 250px;
                            box-shadow: none;
                            overflow: hidden;
                            justify-items: center;
                            align-items: center;
                            .imgContainer{
                                display: flex;
                                width: auto;
                                height: auto;
                                justify-items: center;
                                align-items: center;
                                .cardLogoProject{
                                    display: flex;
                                    width: 100%;
                                    height: auto;
                                    justify-items: center;
                                }
                            }
                        }
                    }
                }
                .nonExpandedGalleryContainer{
                    display: flex;
                    width: auto;
                    height: auto;
                    margin-top: 15px;
                    background: transparent;
                    flex-wrap: wrap;
                    justify-content: center;
                    background: transparent;  
                   
                    .arrayGallery{
                        display: flex;
                        width: 150px;
                        height: 150px;
                        flex-direction: column;
                        background: grey;
                        border-radius: 100%;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        margin: 25px;
                        overflow: hidden;
                        box-shadow: 0px 0px 25px rgba(aqua, 0.80);
                        .arrayGallery.active{
                            display: flex;
                            width: auto;
                            height: auto;
                            margin: 0;
                        .arrayGalleryImg{
                            display: flex;
                            width: auto;
                            height: auto;
                            margin: 0;
                            img{
                                display: flex;
                                width: 100%;
                                height: 100%;
                                justify-items: center;
                                align-items: center;
                                margin: 0;
                            }
                        }  
                           }                                 
                    }
                    :hover{
                        box-shadow: 0px 0px 30px aqua;
                    }
                }
            }
        }
    }
}

//Tablet 1250
@media (max-width: 1250px) {
    .galleryContainer{
        .OCContainer{
            .CardGalleryOC{
                .CardMainContainer{
                    .expandedGalleryContainer{
                        margin-bottom: 2%;
                        .arrayGallery.activeexpanded {
                            width: 300px;
                            height: 300px;
                            .arrayGalleryBlock{
                                .arrayGalleryTitle{
                                    font-size: 20px;
                                }
                                .arrayGalleryDetail{
                                    .cardDetailData{
                                        .textDetailData{
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .nonExpandedGalleryContainer{
                        .arrayGallery{
                            width: 120px;
                            height: 120px;
                        }
                    }
                }
            }
        }
    }
}
//Mobile 485  -320 breakpoint idc
@media (max-width: 485px) {
    .galleryContainer{
        margin-top: 25px;
        .OCContainer{
            .CardGalleryOC{
                .CardMainContainer{
                    margin-top: 25px;
                    .expandedGalleryContainer{
                        margin-bottom: 2%;
                        .arrayGallery.activeexpanded {
                            width: 100%;
                            height: 350px;
                            border-radius: 0;
                            box-shadow: 0px 0px 15px aqua;
                            .arrayGalleryBlock{
                                .arrayGalleryTitle{
                                    font-size: 20px;
                                }
                                .arrayGalleryDetail{
                                    .cardDetailData{
                                        .textDetailData{
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                            .arrayGalleryImg{
                                display: flex;
                                width: auto;
                                height: 250px;
                                box-shadow: none;
                                overflow: hidden;
                                justify-items: center;
                                align-items: center;
                                .imgContainer{
                                    display: flex;
                                    width: auto;
                                    height: auto;
                                    justify-items: center;
                                    align-items: center;
                                     
                                    .cardLogoProject{
                                        display: flex;
                                        width: 115%;
                                        height: 100%;
                                        justify-items: center;

                                    }
                                }
                            }
                        }
                    }
                    .nonExpandedGalleryContainer{
                        .arrayGallery{
                            width: 80px;
                            height: 80px;
                        }
                    }
                }
            }
        }
    }
}    
