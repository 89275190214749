@keyframes opacityAnim {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@import './../VariablesSASS.scss';

.accordionContainer{
    display: flex;
    width: 50%;
    height: auto;
    background: transparent;
    flex-direction: column;
    animation: opacityAnim 2s ease;  
    background: transparent;  
    .titlePBlock{
        display:flex;
        flex-direction: row;
        width: auto;
        height: auto;
        margin-bottom: 15px;
        margin-top: 75px;
        align-items: center;
        background: transparent;  
            .accordionTitle{
                display: flex;
                width: auto;
                height: auto;
                font-size: 33px; 
                color: aqua; 
                background: transparent;       
            }
            .legendTitle{
                display: flex;
                width: auto;
                height: auto;
                color: $base-grey-light;
                margin-top: 7px;
                background: transparent;  
            }
            .workStick{
                display: flex;
                width : 10px;
                height: 10px;
                background:$base-orange;
                margin-right: 3px;
                border-radius: 100%;
                opacity: 80%;
                margin-left: 10px;
                margin-top: 7px;
            }
            .studeStick{
                display: flex;
                width : 10px;
                height: 10px;
                background:$base-blue;
                margin-right: 3px;
                border-radius: 100%;
                opacity: 80%;
                margin-left: 10px;
                margin-top: 7px;
            }
    }   
    .chronoBar{
        display: flex;
        background: white;
        width: 3px;
        height:calc(100% - 40px);
        margin-left: 11px;
        margin-top: 5px;
        position: absolute;
        opacity: 50%;
        z-index: 1;
    }
    .titleShortBlock{
        display: flex;
        flex-direction: column;
        width: auto;
        height: auto;
        margin-top: 40px;
        background: transparent;  
        .inShortTitle{
            display: flex;
            width: auto;
            height: auto;
            font-size: 33px; 
            justify-content: flex-end; 
            margin-bottom: 15px;
            color: aqua;
            background: transparent;  
        }
        .inShortP{
            display: flex;
            font-size: 18px;
            color: rgb(177, 221, 221);
            margin-bottom: 75px;
            background: transparent;  
            p{
                background: transparent;
            }           
        }
    }
    .ulContainer{
        display: flex;
        width: 100%;
        height: auto;
        position: relative;
        flex-direction: column;
        background: transparent;  
        .accordion{         
            display: flex;
            flex-direction: column;
            width: auto;
            height: auto;
            list-style: none; 
            padding: 0; 
            background: transparent;      
            h3{
                display: flex;
                color: $base-blue-light;
                width: auto;
                height: auto;
                margin-top: -3px;
                background: transparent;  
            }
            p{
                display: flex;
                color: $base-grey-light;
                width: auto;
                height: auto;
                margin-bottom: 10px;
                margin-left: 15px;
                background: transparent;  
            }
        }
    }
}
.arrayAbout{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;  
    background: transparent;    
    .aboutBlock{
        display: flex;
        width: auto;
        height: auto;
        flex-direction: column;
        margin-left: 5px;
        justify-content: center;
        margin-bottom: 5px;
        background: transparent;  
        .aboutTitles{
            display: flex;
            width: auto;
            flex-direction: row;
            background: transparent;  
            .pointTitles{
                display: flex;
                background-color: white;
                border-radius: 100%;
                width: 15px;
                height: 15px;
                margin-top: 4px;
                z-index: 2;
            }
            .accordionItemBtn {
                display: flex;
                align-items: center;
                width: auto;
                background: transparent;
                border: 0;
                font-size: 18px;
                font-weight: 400;
                cursor: pointer;
                user-select: none;
                background: transparent;  
            } 
            :hover{
                color:grey;
            }  
            .stickersBlock {
                display: flex;
                width : auto;
                height: auto;
                background: transparent;  
                .stickBlock{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background: transparent;  
                    .workStick{
                        display: flex;
                        width : 10px;
                        height: 10px;
                        background: $base-orange;
                        margin-right: 3px;
                        border-radius: 100%;
                        opacity: 90%;
                    }
                    .studeStick{
                        display: flex;
                        width : 10px;
                        height: 10px;
                        background: $base-blue;
                        margin-right: 3px;
                        border-radius: 100%;
                        opacity: 90%;
                    }
                }
            }          
        }
        .aboutContent{
            display: flex;
            width: auto;
            height: auto;
            margin-left: 25px;
            margin-top: 5px;
            color: rgb(161, 214, 214);
            background: transparent;  
            .accordionItemContent {
                display: flex;
                overflow: hidden;
                background: transparent;  
                .contentBlockAbout{
                    background: transparent;
                }
            }
        }
    }  
}    

//Tablet 1250
@media (max-width: 1250px) {
    .accordionContainer{
        width: 90%;
    }
}
//Mobile 485  -320 breakpoint idc
@media (max-width: 485px) {
    .accordionContainer{
        .titlePBlock{
            margin-top: 25px;
                .accordionTitle{
                    font-size: 25px;
                }
        }   
        .titleShortBlock{
            margin-top: 20px;
            .inShortTitle{
                font-size: 25px; 
            }
            .inShortP{
                display: flex;
                font-size: 14px;
                color: rgb(177, 221, 221);
                margin-bottom: 25px;
            }
        }
        .ulContainer{
            .accordion{         
                h3{
                    font-size: 14px;
                }
                p{
                    font-size: 14px;
                }
            }
        }
    }
    .arrayAbout{
        .aboutBlock{
            .aboutTitles{
                .pointTitles{
                }
                .accordionItemBtn {
                } 
                :hover{
                }  
                .stickersBlock {
                    .stickBlock{
                        .workStick{
                        }
                        .studeStick{
                        }
                    }
                }          
            }
            .aboutContent{
                .accordionItemContent {
                }
            }
        }  
    }    
}