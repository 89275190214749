.background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../public/images/line_orange.png');
    background-repeat: no-repeat;
    overflow:auto;
    background-attachment: fixed; /* Optionnel, fixe l'image pendant le défilement */
    background-size: 115% 115%;
    opacity: 0.2;
    z-index: 1;  
}

// Tablet 1250
@media (max-width: 1250px) {
    .background-container{
        background-size: 100% 100%;
    }
}