//COLORS

$back-Black:#0c0314;

$base-violet:#3e1265;
$base-violet-onDark: #9853d4;

$base-orange:#ff6600;

$base-blue:#0052b4;
$base-blue-light: rgb(177, 221, 221);

$base-grey-light: rgb(160, 160, 160);