@import './VariablesSASS.scss';

nav{
    display: flex;
    width: auto;
    height: auto;
    margin-bottom: 5px;
    margin-right: 15px;
    color: white;
    font-family: light;
    font-size: 35px;
    background-color: transparent;
    .menu-icon {
        display: none;
        margin-top: 0;
        margin-left: 0;
        background-color: transparent;
        text-shadow: none;
        cursor: pointer;
    }
    .navLinksBlock{
        display: flex;
        width: auto;
        height: auto;
        flex-direction: column-reverse;
        background-color: transparent;
        .lien{
            display: flex;
            width: auto;
            height: 60px;
            margin-right: 15px;
            color: white;
            background-color: transparent;           
            :hover{
                color: $base-grey-light;
                transform: color 5sec;
            } 
            &.active a {
                color: aqua;
                transition: color 0.5s;
                // text-shadow: rgba($base-grey-light, 0.226) 0px 0px 5px;
            }
    
            a{
                display: flex;
                white-space: nowrap;
                flex-direction: row-reverse;  
                background-color: transparent;             
            }
        }
    }  
 
// Tablet 1250
@media (max-width: 1250px) {
    
    margin-right: none;

    .menu-icon {
        display: block;
    }

    .navLinksBlock {
        display: none;
        background-color: transparent;
    }

    &.open .navLinksBlock {
        display: flex;
        margin-top: 15px;
        margin-left: 5px;
        flex-direction: column;
        background-color: #333333e0;
        width: auto;
        text-align: left;
        border-radius: 20px;
        flex-direction: column-reverse;
        .lien {
            display: flex;
            width: auto;
            flex-direction: column-reverse;
            background-color: transparent;
            margin-right: none;
            a {
                padding: 10px;
                background-color: transparent;
                flex-direction: none;
                justify-content: center;
                text-shadow: rgba(0, 0, 0, 0.685) 5px 5px 5px;
            }
        }
    }
  }
}