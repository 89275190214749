$strings: (
    "  < hello world > "
    "  < Welcome > "
);

$durCharFwd: 0.10;
$durFullGap: 2.0;
$durCharBwd: 0.08;
$durDoneGap: 1.00;

$charCount: 0;
$durTotal: 0;

@each $string in $strings {
    $charCount: $charCount + str-lenght($string);
    $durTotal: $durTotal 
        + (str-length($string) * ($durCharFwd + $durCharBwd))
        + $durFullGap + $durDoneGap;
}
@function percent($string, $letter, $modifier) {
	$stringsPast: $string - 1; $time: 0;
	@while $stringsPast > 0 {
		$time: $time
			+ (($durCharFwd + $durCharBwd) * (str-length(nth($strings, $stringsPast))))
			+ $durFullGap + $durDoneGap;
		$stringsPast: $stringsPast - 1;
	}
	@if $letter <= str-length(nth($strings, $string)) {
		$time: $time
			+ ($durCharFwd * ($letter - 1));
	} @else {
		$time: $time
			+ ($durCharFwd * str-length(nth($strings, $string)))
			+ $durFullGap
			+ ($durCharBwd * ($letter - str-length(nth($strings, $string))));
	}
	@return ((calc($time / $durTotal)) * 100 + $modifier) + "%";
}
$currentPercentage: 0;

@keyframes typed {
	@for $i from 1 through length($strings) {
		@for $j from 1 through (str-length(nth($strings, $i)) * 2) {
			@if $j < str-length(nth($strings, $i)) * 2 { 
				#{percent($i, $j, 0)}, #{percent($i, $j+1, -0.001)} {
					@if $j <= str-length(nth($strings, $i)) {
						content: quote(#{str_slice(nth($strings, $i), 1, $j)});
					} @else {
						content: quote(#{str_slice(nth($strings, $i), 1, str-length(nth($strings, $i)) - ($j - str-length(nth($strings, $i))))});
					}
				}
			} @else {
				@if $i < length($strings) { 
					#{percent($i, $j, 0)}, #{percent($i+1, 1, -0.001)} {
						content: "​"; 
					}
				} @else { 
					#{percent($i, $j, 0)}, 100% {
						content: "​"; 
					}
				}
			}
		}
	}
}
@keyframes beam-blink {
	75% { border-color: transparent; }
}

.helloWorld {
	display: flex;
    height: 40px;
	align-items: center;
	justify-content: center;
	font-size: 35px;
	font-family: 'VT323', monospace, sans-serif;
	color: aqua; 
	background: transparent;

	&::after {
		content: "​"; 
		position: relative;
		top: -13px;
		@media (max-width: 575px) { top: -33px; }
		display: inline-block;
		padding-right: 3px;
		padding-right: calc(3px + 0.1vw);
		border-right: 10px solid rgba(aqua, 0.75);
		border-right: calc(1.1vw + 4px) solid rgba(aqua, 0.75);
		text-shadow: 0 0 5px aqua;
		white-space: nowrap;
		animation: typed #{$durTotal + "s"} linear 1s infinite, beam-blink 1s infinite;
	}
}

//Mobile 485  -320 breakpoint idc
@media (max-width: 485px) {
	.helloWorld{
		font-size: 25px;
	}
}