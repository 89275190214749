@keyframes opacityAnim {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.contactContainer{
    display: flex;    
    width: auto;
    height: auto;   
    flex-direction: column ;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    animation: opacityAnim 2s ease;     
    z-index: 3;
    background-color: transparent;
    .titleContact{
        display: flex;       
        width: auto;
        height: auto;
        justify-content: center;
        text-align: center;
        margin-bottom: 50px;
        margin-top: 50px;
        font-size: 25px;
        background-color: transparent;
    }
    .contactChoicesContainer{
        display: flex;
        width: auto;
        height: auto;
        justify-content: center;   
        align-items: center;
        flex-direction: column;
        background-color: transparent;
        .contactChoice{
            display: flex;
            width: auto;
            height: auto;
            background-color: transparent;
            img{
                display: flex;
                width: 25%;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                cursor: pointer;
                background-color: transparent;
                &:hover{
                    opacity: 70%;
                }
            }
            a{
                background-color: transparent;
            }
            
        }
    }    
}

// Tablet 1024
@media (max-width: 1024px) {
    .contactContainer{
        .contactChoicesContainer{
            .contactChoice{
                img{
                    width: 50%;
                }
            }
        }
    }
    
}