@import "../misc/accordion.scss";

.AboutContainer{
    display: flex;  
    flex-direction: column;  
    width: 90%;
    height: auto;
    flex: 1;   
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 3;
    background: transparent;
    
    .AccordionMainContainer{
        display: flex;
        width: 100%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 0;
        justify-content: center;
        background: transparent;
    }
}