.DarkLightMainContainer {
    display: flex;
    background-color: rgba(98, 102, 102, 0.527);
    width: 60px;
    height: 30px;
    border-radius: 15px;

    .ChoicesTheme{
        display: flex;
        justify-content: space-between;

        .DarkTheme{
            display: flex;
            width: 30px;
            height: 30px;
            opacity: 50%;
            &:hover{
                opacity: 100%;
            }
        }
        .LightTheme{
            display: flex;
            width: 30px;
            height: 30px;
            opacity: 50%;
            &:hover{
                opacity: 100%;
            }
        }
    }
}