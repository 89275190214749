@import "../misc/helloworld.scss";

@keyframes opacityAnim {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
.HomeContainer{
    display: flex;    
    width: 80%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    z-index: 3;
    background: transparent;
    
    .HomeTexts{ 
        display: flex;       
        flex-direction: column ;
        justify-content: center;
        background: transparent;
        
            p{
                display: flex;
                width: 100%;
                height: auto;
                font-size: 25px;
                justify-content: center;
                margin-bottom: 5px;
                text-align: center;
                animation: opacityAnim 2s ease;   
                background: transparent; 

                span{
                    margin-right:2px;
                    margin-left: 8px;
                    width: auto;
                    height: auto;
                    font-size: 25px;
                    display: flex;
                    flex-wrap: nowrap;
                    color: aqua;
                    flex-direction: raw;
                    justify-content: baseline;
                    background: transparent;
                    // text-align: center;
                }
            }
    }
}

//Tablet 1250
@media (max-width: 1250px) {
    .HomeContainer{
        width: 90%;
    }
}

//Mobile 485  -320 breakpoint idc
@media (max-width: 485px) {
    .HomeContainer{
        .HomeTexts{
            p{
                font-size: 20px;

                span{
                    margin-right:2px;
                    margin-left: 8px;
                    width: auto;
                    height: auto;
                    font-size: 20px;
                    display: flex;
                    flex-wrap: nowrap;
                    color: aqua;
                    // text-align: center;
                }
            }
        }
    }
    
}